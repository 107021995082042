.recent-signup--container {
  margin-top: 30px;
  width: 100%;
}

.recent-signup--container > h1 {
  padding-left: 40px;
}

.recent-signup-list--container {
  text-decoration: none;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.recent-signup-items {
  border: 1px solid gray;
  width: 80%;
  border-radius: 3px;
  padding: 15px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .recent-signup-table {
    width: 90%;
    margin-left: 40px;
  }
}

.recent-signups--page {
  font-size: 12px;
  margin-bottom: 8px;
  color: var(--color-neutral-10);
}
