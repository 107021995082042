.system-data-table--help {
  font-size: 11px;
  color: var(--color-neutral-10);
}

.deployment-candidate--container.deployed {
  background: var(--color-green-2-2);
}

.deployment-candidate--container {
  border-bottom: 1px solid var(--color-neutral-9);
  padding: 16px;
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deployment-candidate--title {
  margin: 8px 0;
}

.deployment-loading--container {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--color-neutral-9);
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 12px;
}

.gates--page {
  font-size: 12px;
  margin-bottom: 8px;
  margin-right: 50px;
  color: var(--color-neutral-10);
}

.connectors--page {
  font-size: 12px;
  margin-bottom: 8px;
  margin-right: 50px;
  color: var(--color-neutral-10);
}

.connectors--button {
  display: flex;
}
