.auth-form--background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: #f4f4f4;
}

.auth-form {
  width: 400px;
  max-width: 90%;
  margin: 40px auto;
  padding: 24px 0 0;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(70, 82, 89, 0.24),
    0 1px 3px 0 rgba(70, 82, 89, 0.12);
}

.auth-form > h1 {
  font-size: 24px;
  text-align: center;
  color: var(--color-blue-3);
}

.auth-form > h3 {
  color: var(--color-blue-3);
  margin-left: 40px;
}

.auth-form label {
  margin-top: 24px;
  margin-bottom: 8px;
  display: block;
}

.auth-modal-form-input--container > label {
  display: none;
}

.auth-modal-form-input--container > input {
  flex-grow: 1;
  background: transparent;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: transparent;
  display: block;
  font-size: 14px;
  border-color: transparent;
  margin-left: 8px;
}

.auth-modal-form-input--container {
  display: flex;
  justify-content: center;
  padding: 12px;
  margin: 16px 40px;
  background: #e8eaea;
  align-items: center;
  color: var(--color-blue-2);
  border-radius: 3px;
}

.auth-modal-form-submit-btn {
  margin-top: 32px;
  padding: 7px 5px;
  width: 100%;
  background: var(--color-green-5);
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--color-green-6);
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.auth-modal-form-submit-btn:hover {
  background: var(--color-green-4);
}

.auth-modal-form-forgot-btn {
  margin: 0 auto;
  width: 250px;
  border-radius: 5px;
  border: 1px solid var(--color-green-6);
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-modal-form-forgot-btn:hover {
  background: var(--color-yellow-3);
}

.tos-link {
  font-size: 12px;
  background: var(--color-yellow-2);
  color: var(--color-blue-3);
  padding: 8px 24px;
  display: block;
  margin: 8px auto;
  width: 80%;
  border-radius: 3px;
  box-sizing: border-box;
}

.tos-link > a {
  text-decoration: none;
}

.auth-modal-form-error {
  width: 80%;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  top: -16px;
  color: var(--color-pink-7);
  height: 0;
}

.auth-modal-form-success {
  width: 80%;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  top: -16px;
  color: var(--color-green-7);
  height: 0;
}

.auth-modal-form-hidden-submit-btn {
  visibility: hidden;
}
