.notifications--container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 16px 24px 0;
  overflow: hidden;
  z-index: 500;
}

.notification-message--dismiss {
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 4px 8px;
}

.notification-message--dismiss:hover {
  color: #dfdfdf;
  background-color: rgba(70, 82, 89, 0.12);
  border-radius: 35px;
}

.notification-message--container {
  padding: 12px 8px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 5px 10px 0 rgba(70, 82, 89, 0.05),
    0 15px 25px 0 rgba(70, 82, 89, 0.15);
  animation: fade-in-up 1 0.2s linear;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.notification-message--container.success {
  background: #21ba45;
}
.notification-message--container.error {
  background: #db2828;
}
.notification-message--container.warning {
  background: #f2711c;
}
.notification-message--container.info {
  background: #2185d0;
}

@keyframes fade-in-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
