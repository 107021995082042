.sidebar-padding--adjustment {
  padding-left: 40px;
}

.user-details--loading-data {
  display: flex;
  align-items: baseline;
  padding-top: 10px;
}

.user-details--loading-data > span {
  padding-right: 2px;
  color: var(--color-neutral-10);
  font-size: 14px;
}

.user-gate-dialog--switch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-gate-dialog--label {
  font-weight: 600;
  color: var(--color-neutral-11);
  margin-top: 24px;
  display: inline-block;
}

/* Added this because of a conflict between react-dates and evergreen ui fighting over the highest z-index */
.DateRangePicker_picker__portal {
  z-index: 20 !important;
}

.SingleDatePicker_picker__portal {
  z-index: 20 !important;
}
