.user-gate-dialog--switch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-gate-dialog-switch--help {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-neutral-9);
}

.user-gate-dialog--label {
  font-weight: 600;
  color: var(--color-neutral-11);
  margin-top: 24px;
  display: inline-block;
}
