:root {
  --color-blue-1: #8b9ea1;
  --color-blue-2: #5c777c;
  --color-blue-3: #2e5057;
  --color-blue-4: #002a32;
  --color-blue-5: #002329;
  --color-blue-6: #001b20;
  --color-blue-7: #001417;
  --color-blue-8: #000c0e;

  --color-orange-1: #fee9db;
  --color-orange-2: #fddbc4;
  --color-orange-3: #fdccac;
  --color-orange-4: #fcbe95;
  --color-orange-5: #fcb07e;
  --color-orange-6: #cf9168;
  --color-orange-7: #a17151;
  --color-orange-8: #73503a;

  --color-yellow-1: #fffaf1;
  --color-yellow-2: #ffefd2;
  --color-yellow-3: #ffb020;
  --color-yellow-4: #996a13;
  --color-yellow-5: #66460d;

  --color-green-1: #dbf1eb;
  --color-green-2: #c3e9dd;
  --color-green-3: #ace0d0;
  --color-green-4: #94d7c3;
  --color-green-5: #7dcfb6;
  --color-green-6: #67aa95;
  --color-green-7: #508474;
  --color-green-8: #395f53;

  --color-pink-1: #f7d4e3;
  --color-pink-2: #f3b8d0;
  --color-pink-3: #ee9bbe;
  --color-pink-4: #e97fab;
  --color-pink-5: #e56399;
  --color-pink-6: #bc527e;
  --color-pink-7: #924062;
  --color-pink-8: #692d46;

  --color-red-1: #fdf4f4;
  --color-red-2: #f9dada;
  --color-red-3: #d14343;
  --color-red-4: #a73636;
  --color-red-5: #7d2828;

  --color-purple-1: #f6f3ff;
  --color-purple-2: #f1ecff;
  --color-purple-3: #d0c0ff;
  --color-purple-4: #a78afc;
  --color-purple-5: #7649fd;
  --color-purple-6: #4f21d7;
  --color-purple-7: #330ea1;

  /* TODO: replace old green/blue with new ones */
  --color-green-2-1: #f5fbf8;
  --color-green-2-2: #dcf2ea;
  --color-green-2-3: #52bd94;
  --color-green-2-4: #317159;

  --color-blue-2-1: #f3f6ff;
  --color-blue-2-2: #d6e0ff;
  --color-blue-2-3: #9db5ff;
  --color-blue-2-4: #3366ff;
  --color-blue-2-5: #2952cc;
  --color-blue-2-6: #1f3d99;

  --color-neutral-1: #ffffff;
  --color-neutral-2: #fafbff;
  --color-neutral-3: #f9fafc;
  --color-neutral-4: #f4f6fa;
  --color-neutral-5: #edeff5;
  --color-neutral-6: #e6e8f0;
  --color-neutral-7: #d8dae5;
  --color-neutral-8: #c1c4d6;
  --color-neutral-9: #8f95b2;
  --color-neutral-10: #696f8c;
  --color-neutral-11: #474d66;
  --color-neutral-12: #101840;

  --box-shadow-1: 0 1px 2px 0 rgba(70, 82, 89, 0.24),
    0 1px 3px 0 rgba(70, 82, 89, 0.12);
  --box-shadow-2: 0 2px 4px 0 rgba(70, 82, 89, 0.12),
    0 3px 6px 0 rgba(70, 82, 89, 0.15);
  --box-shadow-3: 0 3px 6px 0 rgba(70, 82, 89, 0.1),
    0 10px 20px 0 rgba(70, 82, 89, 0.15);
  --box-shadow-4: 0 5px 10px 0 rgba(70, 82, 89, 0.05),
    0 15px 25px 0 rgba(70, 82, 89, 0.15);

  --alt-box-shadow-1: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  --alt-box-shadow-2: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}

.app-bootstrap--loading {
  width: 200px;
  display: flex;
  align-items: baseline;
  margin: 120px auto;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: var(--color-blue-1);
  justify-content: center;
}
