.classify-sidebar--container {
  width: 230px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #e9e9e9;
  color: #7e7e7e;
}

.classify-sidebar-link:hover {
  background: white;
}

.classify-sidebar-link.active {
  background: white;
  color: black;
  box-shadow: var(--box-shadow-2);
}

.classify-sidebar-link {
  color: #7e7e7e;
  padding: 10px 16px;
  margin: 8px 16px;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  border-radius: 3px;
  transition: all 0.3s ease;
  font-size: 14px;
  align-items: center;
}

.sidebar-sibling-content--container {
  margin-left: 230px;
}

.classify-sidebar-links--container {
  margin-top: 32px;
}

.classify-sidebar-link--icon {
  margin-right: 8px;
}

.settings-sidebar--back-link {
  color: blue;
  text-decoration: none;
  margin-left: 18px;
  margin-bottom: 18px;
  display: block;
}
