.user-setting-dialog--row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.user-setting-dialog--label {
  font-weight: 600;
  color: var(--color-neutral-11);
  margin-top: 24px;
  display: inline-block;
}

.user-setting-dialog--switch {
  margin-top: 8px;
  margin-bottom: 8px;
}

.user-setting-dialog-switch--help {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-neutral-9);
}
